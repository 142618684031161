<template>
  <ChangeCase></ChangeCase>
</template>

<script>
import ChangeCase from "./components/ChangeCase.vue";

export default {
  name: "App",
  components: {
    ChangeCase,
  },
};
</script>

<style lang="scss"></style>
